import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../app-services/crud-service.service'
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
    public isLoading: boolean = false;
    public orderList: any;
    public productList = [];
    public prodInfo = [];
    public taxValue = 0;
    public shippingCharges;
    public totalTax;
    p: number = 1;
    constructor(private modalService: NgbModal, private crud: CrudServiceService, private toastr: ToastrService) {
        this.orderHistoryList();
        //this.taxValue = +localStorage.getItem("taxValue");
        // console.log("taxValue", this.taxValue)
    }
    public modalReference: NgbModalRef;
    //get order history
    public orderHistoryList() {
        this.isLoading = true;
        this.crud.getData("customer/order/history?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode == 200) {
                // console.log("res", JSON.stringify(res))
                this.orderList = res.salesOrders;
                this.shippingCharges = res.shippingCharges;
                this.totalTax = res.totalTax;
                this.orderList.forEach((ele) => {
                    let totalPrice = 0;
                    ele.productList.forEach(data => {
                        totalPrice += +data.price;
                        
                    })
                    // console.log("totalPrice", totalPrice)
                    //ele.taxValue = (totalPrice * this.taxValue) / 100
                })
                // console.log("sdrftgyhj", this.orderList)
                this.isLoading = false;
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            this.isLoading = false;
        })
    }
    //order Details 
    openOrderDetail(content, productList, data) {
        this.modalReference = this.modalService.open(content, { backdrop: true, keyboard: false });
        // console.log("data.productList", productList)
        this.productList = productList;
        this.prodInfo = data;
    }
    ngOnInit() {
    }
}
