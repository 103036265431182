import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddToCartService {
    public cartData = new BehaviorSubject(null);
    public cartDataEmitter = this.cartData.asObservable();

    public emitCartInfo(i): void {
        console.log("i", i)
        this.cartData.next(i);
    }
}