import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { HttpHeaders } from '@angular/common/http';
import { Http, RequestOptions, } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Headers } from '@angular/http';
import { CookieService } from "ngx-cookie";
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'selenium-webdriver/chrome';
import { Router } from '@angular/router'
declare const require: any;
var qs = require('qs');
var assert = require('assert');
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isRememberMe: boolean = false;
  public signIn: boolean = true;
  public signUp: boolean = false;
  public forgetPasswordEnable: boolean = false;
  public modalReference: NgbModalRef;
  public headers: HttpHeaders = new HttpHeaders();
  public registering: boolean = false;
  public logingIn: boolean = false;
  public forgetPassword = {
    email: ''
  }
  public forgetPasswordOpen() {
    this.signIn = false;
    this.signUp = false;
    this.forgetPasswordEnable = true;
  }
  public loginDetails = {
    email: null,
    password: null
  };
  public signUpDetails = {
    fname: null,
    lname: null,
    //phoneNum: null,
    email: null,
    city: null,
    password: null,
   // state: null,
   // address: null,
   // country: null,
   // zip: null,
    branchName: "Online",
    // customerId: null
  }
  public responseData = {};
  constructor(private cookieService: CookieService, private crud: CrudServiceService, private http: Http, private toastr: ToastrService, private modalService: NgbModal, private route: Router) {
  }
  public login() {
    this.logingIn = true;
    if (this.isRememberMe === true) {
      this.cookieService.putObject('user', this.loginDetails);
    }
    fetch(this.crud.getEndPoint("customer/authenticate"),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
        },
        body: qs.stringify({
          email: this.loginDetails.email,
          password: this.loginDetails.password,
        })
      }).then(response => response.json())
      .then(response => {
        if (response.responseCode == "200") {
          // console.log("login", response)
          this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
          if (localStorage.getItem("isloggedIn") == null) {
            localStorage.setItem("isloggedIn", "true");
            localStorage.setItem("customerid", response.customers[0].customerid)
            localStorage.setItem("email", response.customers[0].email);
            localStorage.setItem("email", response.customers[0].email);
            localStorage.setItem("firstname", response.customers[0].firstname);
            localStorage.setItem("lastname", response.customers[0].lastname);
            localStorage.setItem("phonenum", response.customers[0].phonenum);
            localStorage.setItem("type", response.customers[0].type)
          } else {
            localStorage.getItem("isloggedIn")
          }
          this.route.navigate(['/home']);
          this.logingIn = false;
        } else if (response.responseCode == "100") {
          this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
          this.logingIn = false;
        }
      })
      .catch(error => console.error('Error:', error));
  }
  selectForm(signUpContent) {
    this.modalReference = this.modalService.open(signUpContent, { backdrop: false, keyboard: false });
  }
  closeModel() {
    this.modalReference.close();
  }
  openSignUp() {
    this.signIn = false;
    this.forgetPasswordEnable = false;
    this.signUp = true;
  }
  openSignIn() {
    this.signIn = true;
    this.signUp = false;
    this.forgetPasswordEnable = false;
  }
  signUpData() {
    this.registering = true;
   // fetch('https://merchant.scantranx.com/api/v1/customer/create',
    fetch(this.crud.getEndPoint("customer/create"),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
        },
        body: qs.stringify({
          fname: this.signUpDetails.fname,
          lname: this.signUpDetails.lname,
         // phoneNum: this.signUpDetails.phoneNum,
          email: this.signUpDetails.email,
         // city: this.signUpDetails.city,
          password: this.signUpDetails.password,
         // state: this.signUpDetails.state,
         // address: this.signUpDetails.address,
         // country: this.signUpDetails.country,
         // zip: this.signUpDetails.zip,
          branchName: this.signUpDetails.branchName,
          // customerId: this.signUpDetails.customerId,
        })
      }).then(response => response.json())
      .then(response => {
        if (response.responseCode == "200") {

          this.toastr.success(response.responseMessage,"Registration Successfull!", { timeOut: 3000 })
          this.signIn = true;
          this.signUp = false;
          this.registering = false;
          localStorage.setItem("isloggedIn", "true");
          localStorage.setItem("customerid", response.customers[0].customerid)
          localStorage.setItem("email", response.customers[0].email);             
          localStorage.setItem("firstname", response.customers[0].firstname);
          localStorage.setItem("lastname", response.customers[0].lastname);
          localStorage.setItem("phonenum", response.customers[0].phonenum);
          localStorage.setItem("type", response.customers[0].type);  
          this.route.navigate(['/home']);          

        } else if (response.responseCode == "100") {
          this.registering = false;
          this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
        }
      })
      .catch(error => console.error('Error:', error));
  }
  public backToLogin() {
    this.signIn = true;
    this.signUp = false;
    this.forgetPasswordEnable = false;
  }
  public forgetPasswordSubmit() {
    fetch(this.crud.getEndPoint("customer/password/resend"),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
        },
        body: qs.stringify({
          email: this.forgetPassword.email
        })
      }).then(response => response.json())
      .then(response => {
        if (response.responseCode == "200") {
          this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
          this.logingIn = false;
        } else if (response.responseCode == "100") {
          this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
          this.logingIn = false;
        }
      })
      .catch(error => console.error('Error:', error));
  }
  ngOnInit() {
  }

}
