 import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { FeaturedProductsModel, CategoryList } from '../../shared/models/home.model';
import { HelperService } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { ProductModel } from '../../shared/models/product.model';
import { timeout } from 'q';
declare var $: any;
//declare const initJs: any;
declare const require: any;
var qs = require('qs');

@Component({ selector: 'app-home', templateUrl: './home.component.html', styleUrls: ['./home.component.scss'] })
export class HomeComponent implements OnInit, AfterViewInit {
    public selectedColor:string;
    public selectedColorId:any;
    public selectedSize:string;
    @Input() selectedSizeId:any;    

    public treandingProducts: any = [];
    public featuredProductList: Array<FeaturedProductsModel> = [];
    public categoryList: Array<CategoryList> = [];
    public brandList = [];
    public bannerList = {};
    public Image = "assets/img/deafultImg.png";
    public featuredBrand = [];
    public featuredBanner: any = {};
    public productQuant: number = 1;
    public colorOption=null;
    public sizeOption=null;

    constructor(private router: Router, private crud: CrudServiceService, private toastr: ToastrService, private helpService: HelperService) {
        this.getAllCategoryList();
        this.getFeaturedProducts();
        this.getBannerList();
        this.getFeaturedBrand();
        this.getBrandList();
        this.getMerchantInfo();
        this.getCartList()
    }
    //cartList
    getCartList() {
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode = "200") {

            }
        })
    }
    //marchantInfo
    getMerchantInfo() {
        this.crud.getData('merchant/config').subscribe((res) => {
            if (res.responseCode == 200) {
                localStorage.setItem("taxValue", res.taxValue);
                localStorage.setItem("paymentGateWays", JSON.stringify(res.paymentGateWays))
                localStorage.setItem("currency", JSON.stringify(res.currency))
            }
        })
    }
    //get brand
    getBrandList() {
        this.crud.getData('brand/list').subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.brandList = res.brands
            }
            // console.log("brand", res)
        })
    }
    public brandLogoCarousel = {
        items: 8,
        dots: false,
        navigation: false,
        autoplay: true,
        loop: true,
        responsive: {
            0: {
                items: 2
            },
            400: {
                items: 2
            },
            500: {
                items: 2
            },
            768: {
                items: 3
            },
            1024: {
                items: 4
            },
            1292: {
                items: 4
            }
        },
    };


    //get featured brand 
    getFeaturedBrand() {
        this.crud.getData("banner/featured").subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.featuredBanner = res.banners[0];
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        });

        this.crud.getData('brand/featured').subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.featuredBrand = res.brands;
            }
        }, error => {
            this.toastr.error("Something went wrong", 'Error', { timeOut: 3000 });
        });

    }
    //get banner List
    getBannerList() {
        this.crud.getData("banner/sliders").subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.bannerList = res.sliders;
                // console.log("bannerList", this.bannerList[0])
            }
        }
            , error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            }
        )
    }
    //get categoryList
    getAllCategoryList() {
        this.crud.getData("category/featured").subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.categoryList = res.categories;
                // console.log(this.categoryList)
            }
        }
            , error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            }
        )
    }
    //get fetured Products
    getFeaturedProducts() {
        this.crud.getData("product/featured").subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.featuredProductList = res.products;

                this.treandingProducts = this.chunk(res.products, 2);

                console.log("Treanding Products");
                console.log(this.treandingProducts);

            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }
    showProducts(id) {
        let body = {
            catId: id,
            subCatId: null
        }
        this.helpService.subCat.next(body);
        this.router.navigate(['/collectionByCategory/' + id])
    }
    ngOnInit() {
        this.helpService.cartNumber.subscribe((numb: any) => {
            // console.log("from home", numb)
        })
    }
    collectionPage(data) {
        this.router.navigate(['/catId/' + data.categoryId + '/product-detalil/' + data.prdcode])
    }

    addToCart(code,$event) {
       
        var el = $($event.target).parents('.product_thumb').next().find(".variation-option-size").children('select').val();
        var options = this.getColorAndSizeByVariation(el,code);        

        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            fetch(this.crud.getEndPoint("cart/add"),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                    },
                    body: qs.stringify({
                        email: localStorage.getItem("email"),
                        customerId: localStorage.getItem("customerid"),
                        productId: code,
                        variationId:el,
                        color:options.color,
                        size:options.size,
                        quantity: this.productQuant
                    })
                }).then(response => response.json())
                .then(response => {
                    if (response.responseCode == "200") {
                        this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                        this.router.navigate(['/cart-page'])
                    }
                }).catch(error => console.error('Error:', error))
        }
    }

    public addToWishList(prodId,$event) {

        var el = $($event.target).parents('.product_thumb').next().find(".variation-option-size").children('select').val();
        var options = this.getColorAndSizeByVariation(el,prodId);   

        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            if (localStorage.getItem("customerid") !== null || localStorage.getItem("customerid") !== undefined) {
                fetch(this.crud.getEndPoint("wishlist/create"),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                        },
                        body: qs.stringify({
                            customerId: localStorage.getItem("customerid"),
                            variationId:el,
                            color:options.color,
                            size:options.size,
                            productId: prodId
                        })
                    }).then(response => response.json())
                    .then(response => {
                        if (response.responseCode == "200") {
                            this.helpService.wishListSubject.next(response.wishListItems)
                            this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                            this.router.navigate(['/wishlist']);
                        }
                    }).catch(error => console.error('Error:', error));

            } else {
                this.toastr.warning("Please Login First", "Login In Required", { timeOut: 3000 })
            }
        }
    }

    ngAfterViewInit() {
        //initJs();
        setTimeout(function () {
            $('.product_column5').on('changed.owl.carousel initialized.owl.carousel', function (event) {
                $(event.target).find('.owl-item').removeClass('last').eq(event.item.index + event.page.size - 1).addClass('last')
            }).owlCarousel({
                autoplay: true,
                loop: true,
                nav: true,
                autoplayTimeout: 8000,
                items: 5,
                margin: 20,
                dots: false,
                navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 2,
                    },
                    768: {
                        items: 3,
                    },
                    992: {
                        items: 4,
                    },
                    1200: {
                        items: 5,
                    },
                }
            });

            $('.slider_area').owlCarousel({
                animateOut: 'fadeOut',
                autoplay: true,
                loop: true,
                nav: false,
                autoplayTimeout: 8000,
                items: 1,
                dots: true,
            });

            $('.brand_container').on('changed.owl.carousel initialized.owl.carousel', function (event) {
                $(event.target).find('.owl-item').removeClass('last').eq(event.item.index + event.page.size - 1).addClass('last')
            }).owlCarousel({
                autoplay: true,
                loop: true,
                nav: false,
                autoplayTimeout: 8000,
                items: 5,
                margin: 20,
                dots: false,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                    },
                    300: {
                        items: 2,
                        margin: 15,
                    },
                    480: {
                        items: 3,
                    },
                    768: {
                        items: 4,
                    },
                    992: {
                        items: 5,
                    },
                }
            });

        }, 3000);
    }


    chunk(array, size) {
        const chunked_arr = [];
        let index = 0;
        while (index < array.length) {
            chunked_arr.push(array.slice(index, size + index));
            index += size;
        }
        return chunked_arr;
    }

    onSizeAndColorChange(variationId:string,prdcode:string,$event): void 
    {
       var options = this.getColorAndSizeByVariation(variationId,prdcode);                    
      
        $($event.target).parent().siblings().find('select').val(variationId).change();

        this.colorOption = options.color;
        this.sizeOption =  options.size;
    }

    getColorAndSizeByVariation(variationId,prdcode)
    {
        let color,size = null;

        const products:FeaturedProductsModel[] =  this.featuredProductList.filter((product) => {
            return product.prdcode == prdcode;
        });

        const product = products.length? products[0]: null;        

        for(let i = 0; i < product.variations.length; i++){
            if(product.variations[i].id == variationId){
                color = product.variations[i].color;
                size = product.variations[i].size;
            }           
        }    

        return {color,size};
    }
}
