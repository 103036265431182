import * as CartActions from './cart.action';

export interface CartState {
    cart: State;          // creates a abstract field cart
    vendorId: State;       // creates a abstract field vendor id
    isAuthenticated: State;    // creates an abstract field isAuthenticated
}

export interface State {
    cart: Array<any>;       // creates an abstract field cart of type array
    vendorId: string;       // creates an abstract field vendor id of type string
    isAuthenticated: boolean;    // contains user's authentication status
}

const initialState: State = {
    cart: (localStorage.getItem('cart') !== null) ? JSON.parse(atob(localStorage.getItem('cart'))) : [],
    vendorId: null,
    isAuthenticated: (localStorage.getItem('userCredentials') !== null)
};      // sets default value of cart data

export function cartReducer(state = initialState, action: CartActions.CartAction) {
    switch (action.type) {
        case 'ADD_TO_CART':
            // adds an item to cart
            return {
                ...state,
                cart: [...action.payload.cart]
            };
        case 'UPDATE_CART_ITEM':
            // updates cart item
            const cartInfo = state.cart[action.payload.index];
            const updatedCart = {
                ...cartInfo,
                ...action.payload.cart
            };
            const cartData = [...state.cart];
            cartData[action.payload.index] = updatedCart;
            return {
                ...state,
                cart: cartData
            };
        case 'DELETE_ITEM':
            const cartItems = [...state.cart];
            cartItems.splice(action.payload.index, 1);
            return {
                ...state,
                cart: cartItems
            };
        case 'SET_VENDOR_ID':
            return {
                ...state,
                vendorId: action.payload.vendorId
            };

        case 'AUTH_STATUS':
            return {
                ...state,
                isAuthenticated: action.payload.status
            };
        default:
            return state;
    }
}

