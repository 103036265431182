
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class CrudServiceService {

     private authToken: string ="Basic c2hvcHB5OjExODk0MDY0";
    // returns authentication token
    get getAuthToken(): string {
        return this.authToken;
    }

    // sets authentication token
    set initializeAuthToken(token: string) {
        this.authToken = token;
    }

    // attaches server base url with api point
    public getEndPoint(url: string): string {
        return environment.API_ENDPOINT + url;
    }

    constructor(private http: HttpClient) { }

    // gets list of records
    public getData(url: string): Observable<any> {
        return this.http.get(this.getEndPoint(url));
    }

    // sends request to server to create new record
    public saveData(url: string, data): Observable<any> {
        return this.http.post(this.getEndPoint(url), data);
    }

    // sends request to server to update data
    public updateData(url: string, data): Observable<any> {
        return this.http.put(this.getEndPoint(url), data);
    }

    // sends request to server to update data
    public deleteData(url: string): Observable<any> {
        return this.http.delete(this.getEndPoint(url));
    }
}
