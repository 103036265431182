import { Component, OnInit,AfterViewInit  } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-full',
	templateUrl: './full.component.html',
	styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit,AfterViewInit  {
	routerSubscription: any;

	constructor(private router: Router) { }

	ngOnInit() {
		//this.recallJsFuntions();
	}

	// recallJsFuntions() {
	// 	this.routerSubscription = this.router.events
	// 	.pipe(filter(event => event instanceof NavigationEnd))
	// 	.subscribe(event => {
	// 		$.getScript('/assets/js/main.js');
	// 	});
	// }

	// ngOnDestroy() {
	// 	this.routerSubscription.unsubscribe();
	// }

	ngAfterViewInit() {
       
    }
}
