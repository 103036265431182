import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-variations',
	templateUrl: './variations.component.html',
	styleUrls: ['./variations.component.scss']
})
export class VariationsComponent implements OnInit {

	@Input() defaultColor: string = "";
	@Input() defaultSize: string = "";
	@Input() variations = [];

	colors : []
	sizes : []

	constructor() { }

	ngOnInit() {

		this.colors = this.variations.reduce((a, c) => {
			a.push(c['color']);
			return a;
		}, []);
		console.log("Colors", this.colors);
		this.sizes =  this.variations.reduce((a,c) => {
			a.push(c['size']);
			return a;
		},[]);

		console.log("Sizes", this.sizes);

	}

	onSizeChange() {

	}

	onColorChange(){

	}
}
