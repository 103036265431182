import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { HttpHeaders } from '@angular/common/http';
import { Http, RequestOptions, } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Headers } from '@angular/http';
import { CookieService } from "ngx-cookie";
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'selenium-webdriver/chrome';
import { Router } from '@angular/router'
declare const require: any;
var qs = require('qs');
var assert = require('assert');

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	public forgetPassword = {
		email: ''
	}

	constructor(private cookieService: CookieService, 
		private crud: CrudServiceService, 
		private http: Http, 
		private toastr: ToastrService, 
		private modalService: NgbModal, 
		private route: Router) 
	{ 

	}

	ngOnInit() {
	}

	public forgetPasswordSubmit() {
		fetch(this.crud.getEndPoint("customer/password/resend"),
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
			},
			body: qs.stringify({
				email: this.forgetPassword.email
			})
		}).then(response => response.json())
		.then(response => {
			if (response.responseCode == "200") {
				this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
				this.route.navigate(['/login']);				
			} else if (response.responseCode == "100") {
				this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })				
			}
		})
		.catch(error => console.error('Error:', error));
	} 
}
