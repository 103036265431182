import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './layout/content/content.component'
import { FullComponent } from '././layout/full/full.component';
import { CONTENT_ROUTES } from '././shared/routes/content.routes'
import { FULL_ROUTES } from '././shared/routes/full.routes'
const appRoutes: Routes = [
    {
        path: 'home',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    { path: '', component: FullComponent, data: { title: 'full Views' }, children: FULL_ROUTES },
    { path: '', component: ContentComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {useHash: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}