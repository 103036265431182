import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { OrderService } from '../../services/orderService.service';
import { Router } from '@angular/router'
declare const require: any;
declare var $: any;
var qs = require('qs');

@Component({
	selector: 'app-shipping-company',
	templateUrl: './shipping-company.component.html',
	styleUrls: ['./shipping-company.component.scss']
})
export class ShippingCompanyComponent implements OnInit {

	public isLoading: boolean = false;
	public productInfo: any = {};
	public cartListItem = [];
	public subTotal = 0;
	public shippingCompanies = [];
        public tax = 0;
        public total = 0;

        public orderNote = "";
        public phoneNo = "";

	constructor(private router: Router, 
		private order: OrderService, 
		private toastr: ToastrService, 
		private modalService: NgbModal, 
		private crud: CrudServiceService
		) 
	{ 
		this.getCartData();
		this.getShippingCompany();
	}

	ngOnInit() {
		this.order.orderData.subscribe((res: any) => {           
            this.productInfo = res;
        });
        
        if( this.productInfo == null ){
            this.router.navigate(['/cart-page']);
        }
	}

	getShippingCompany()
	{
		this.crud.getData("shipping/company/list").subscribe((response: any) => {
            if (response.responseCode = "200") {                
                this.shippingCompanies = response.shippingCompanys;
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                this.isLoading = false;
            }
            });		
	}

	//get cart data
    getCartData() {
        
        this.isLoading = true;
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            
            if (res.responseCode = "200") {
                this.cartListItem = res.cartItems;               
                this.subTotal = 0;
                this.cartListItem.forEach((element) => {
                    this.subTotal += (element.quantity * element.price);                  
                });

                this.calTax();
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                this.isLoading = false;
            }
        })
    }

    calTax() {
        this.tax = (+this.subTotal * +localStorage.getItem("taxValue")) / 100;
        this.total = this.subTotal + this.tax;        
        localStorage.setItem("cartTotal", String(this.total))
    }

    selectShipping($event,id){ 
        var elems = document.querySelectorAll(".btn.selected-shipping");
        [].forEach.call(elems, function(el) {
            el.classList.remove("selected-shipping");
        });
        $event.target.classList.add("selected-shipping");

        this.productInfo.scompanyid = id;           
               
    }

    proceedToPay(){
        var elems = document.querySelectorAll(".btn.selected-shipping");
        if(elems.length <= 0){
            this.toastr.warning("warning", "Please select a Shipping Option", { timeOut: 3000 });
            return false;
        }  
    	this.order.emitOrderInfo(this.productInfo);
        this.placeOrder();
    }

    placeOrder() {
        fetch(this.crud.getEndPoint("sales/order/initiate"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                customerId: localStorage.getItem("customerid"),
                addressId: this.productInfo.addressId,
                subTotal: this.productInfo.subTotal,
                totalPrice: this.productInfo.grandTotal,
                shippingCharges: null,
                pickupLocationId:0,
                notes:this.orderNote,
                phoneNumber:this.phoneNo

            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                this.productInfo.currency = this.cartListItem[0].currency;
                this.productInfo.orderId = response.orderId;
                this.productInfo.orderStatus = response.orderStatus;
                this.productInfo.notes = this.orderNote;
                this.productInfo.phoneNumber = this.phoneNo;
                this.order.emitOrderInfo(this.productInfo);
                this.router.navigate(['/payment']);               
                //this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 });                
            }
        })
        .catch(error => console.error('Error:', error));
    }   
}
