import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appSizeAndColorSync]'
})
export class SizeAndColorSyncDirective implements OnInit {

  @Input() variation: any;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    console.log(' ddddddddddddd', this.variation)
  }

}
