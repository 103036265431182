import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { ProductsListModel } from '../../shared/models/collection.model';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../app.service';
declare var $: any;
declare const require: any;
var qs = require('qs');
@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
    public isLoading: boolean = false;
    public p = 1;
    public productList: Array<ProductsListModel> = [];
    public categoryId: Number = null;
    public brandList = [];
    public subCatFilter = [];
    public sizeList = [];
    public subIdCat: null;
    public selectedId = null;
    public filterBar = false;
    public filterBtn = true;
    public productQuant: number = 1;
    public submitingData: boolean = false;   
    public collectionList = [{ title: "Brand 1", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 2", subTitle: "Men Tees", Price: 567, image: "assets/img/pic4.png" }, { title: "Brand 3", subTitle: "Men Tees", Price: 567, image: "assets/img/pic5.png" }, { title: "Brand 4", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 1", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 2", subTitle: "Men Tees", Price: 567, image: "assets/img/pic4.png" }, { title: "Brand 3", subTitle: "Men Tees", Price: 567, image: "assets/img/pic5.png" }, { title: "Brand 4", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 1", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 2", subTitle: "Men Tees", Price: 567, image: "assets/img/pic4.png" }, { title: "Brand 3", subTitle: "Men Tees", Price: 567, image: "assets/img/pic5.png" }, { title: "Brand 4", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }]

    constructor(private router: Router, private helpService: HelperService, private activeRoute: ActivatedRoute, private crud: CrudServiceService, private toastr: ToastrService) {
        this.activeRoute.params.subscribe((res: any) => {
            this.categoryId = res.id;
            if ((this.categoryId < 10000000000)) {
                // this.getProductById();
                // this.getSubCategoriesList(this.categoryId);
                // this.subCategoryFilterList();
            } else {
                this.searchList();
            }
            this.getSizeList();
        });
        this.checkUser();
    }

    searchList() {
        this.isLoading = true;
        this.crud.getData("product/search?productName=" + this.categoryId + "&channel=1").subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.categoryId = res.products[0].categoryId
                // console.log("response", res.products)
                this.productList = res.products;
                if (this.productList.length > 0) {
                    this.getSubCategoriesList(res.products[0].categoryId)
                }
                this.crud.getData('category/subcategory/list?categoryId=' + res.products[0].categoryId).subscribe((res: any) => {
                    if (res.responseCode == 200) {
                        this.subCatFilter = res.subcategories;
                    } else {
                        this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
                    };

                })
                // console.log("qwerty", this.productList)
                this.isLoading = false;
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }

    checkUser() {
        if (localStorage.getItem("type") === "Regular" || localStorage.getItem("type") == undefined) {
            return false;
        } else {
            return true;
        }
    }
    getProductById() {
        this.isLoading = true;
        if (this.categoryId !== null) {
            this.crud.getData("product/list?categoryId=" + this.categoryId + "&channel=1").subscribe((res: any) => {
                if (res.responseCode == 200) {
                    this.productList = res.products;
                    this.isLoading = false;
                }
                // console.log('resdata', res)
            }, error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            }
            )
        }
    }
    public productDetails(id, catId) {
        // console.log(id, catId)
        this.router.navigate(['/catId/' + catId + '/product-detalil/' + id])
    }
    // sub category Filter
    subCategoryFilterList() {
        this.crud.getData('category/subcategory/list?categoryId=' + this.categoryId).subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.subCatFilter = res.subcategories;
            } else {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
            };

        })
    }
    //get sub CatId
    subCatIdSelect(id) {
        this.selectedId = id;
        this.crud.getData("product/subcategory/list?subcategorId=" + id + "&categoryId=" + this.categoryId + "&channel=1").subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.productList = res.products;

            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }
    //sub categories
    getSubCategoriesList(id) {
        this.isLoading = true;
        if (id !== null) {
            this.crud.getData('category/brand/list?categoryId=' + id).subscribe((res: any) => {
                if (res.responseCode == 200) {
                    this.brandList = res.brands;
                    // console.log('resdataSub', res)
                }
            }, error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            }
            )
        }
    }
    //productByBrand
    productByBrand(brandId) {
        this.crud.getData("product/brand/list?brandId=" + brandId + "&channel=1").subscribe((res) => {
            // console.log("resadata", res)
            if (res.responseCode == 200) {
                this.productList = res.products
            }
        })
    }

    getSizeList() {
        this.crud.getData("size/list").subscribe((res) => {
            if (res.responseCode == 200) {
                this.sizeList = res.sizes
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }
    selectSize(id) {
        this.crud.getData("product/size/list?sizeId=" + id + "&categoryId=" + this.categoryId + "&channel=1").subscribe((res) => {
            if (res.responseCode = 200) {
                this.productList = res.products;
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }
    ngOnInit() {
        this.helpService.subCat.subscribe((res: any) => {
            if (res) {
                if (res.catId !== null && res.subCatId !== null) {
                    this.isLoading = true;
                    this.selectedId = res.subCatId;
                    this.crud.getData("product/subcategory/list?subcategorId=" + res.subCatId + "&categoryId=" + res.catId + "&channel=1").subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.productList = res.products;
                            console.log('first',res);
                            this.isLoading = false;
                        }
                    }, error => {
                        this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
                        this.isLoading = false;
                    })
                    this.getSubCategoriesList(res.catId);
                    this.crud.getData('category/subcategory/list?categoryId=' + res.catId).subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.subCatFilter = res.subcategories;
                        } else {
                            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
                        };

                    })
                }
                if (res.catId !== null && res.subCatId == null) {
                    this.isLoading = true;
                    this.crud.getData("product/list?categoryId=" + res.catId + "&channel=1").subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.productList = res.products;
                            this.isLoading = false;
                        }
                    })
                    this.getSubCategoriesList(res.catId);
                    this.crud.getData('category/subcategory/list?categoryId=' + res.catId).subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.subCatFilter = res.subcategories;
                        } else {
                            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
                        };

                    })
                }
            }
        })
    }
    openFilterMenu() {
        // console.log('this fn is called kakfksahfdk akshdfks ');
        this.filterBar = true;
        this.filterBtn = !this.filterBtn;
    }

    closeFilterMenu() {

        this.filterBar = false;
        this.filterBtn = !this.filterBtn;
    }

    addToCart(code,$event) {
        var el = $($event.target).parents('.product_thumb').next().find(".variation-option-size").children('select').val();
        var options = this.getColorAndSizeByVariation(el,code);

        // console.log(localStorage.getItem("customerid"))
        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            fetch(this.crud.getEndPoint("cart/add"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                },
                body: qs.stringify({
                    email: localStorage.getItem("email"),
                    customerId: localStorage.getItem("customerid"),
                    productId: code,
                    variationId:el,
                    color:options.color,
                    size:options.size,
                    quantity: this.productQuant
                })
            }).then(response => response.json())
            .then(response => {
                if (response.responseCode == "200") {
                    // console.log("added", response)
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                    this.router.navigate(['/cart-page'])
                }
            }).catch(error => console.error('Error:', error))
        }
    }

    public addToWishList(prodId,$event) {
        
        var el = $($event.target).parents('.product_thumb').next().find(".variation-option-size").children('select').val();
        var options = this.getColorAndSizeByVariation(el,prodId);

        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            if (localStorage.getItem("customerid") !== null || localStorage.getItem("customerid") !== undefined) {
                fetch(this.crud.getEndPoint("wishlist/create"),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                    },
                    body: qs.stringify({
                        customerId: localStorage.getItem("customerid"),
                        variationId:el,
                        color:options.color,
                        size:options.size,
                        productId: prodId
                    })
                }).then(response => response.json())
                .then(response => {
                    if (response.responseCode == "200") {
                        this.helpService.wishListSubject.next(response.wishListItems)
                        this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                        this.router.navigate(['/wishlist']);
                        this.submitingData = false;
                    }
                }).catch(error => console.error('Error:', error));
                this.submitingData = false
            } else {
                this.toastr.warning("Please Login First", "Login In Required", { timeOut: 3000 })
                this.submitingData = false
            }
        }
    }

    onSizeAndColorChange(variationId:string,prdcode:string,$event): void 
    {
       var options = this.getColorAndSizeByVariation(variationId,prdcode);                    
      
        $($event.target).parent().siblings().find('select').val(variationId).change();

        //this.colorOption = options.color;
        //this.sizeOption =  options.size;
    }

    getColorAndSizeByVariation(variationId,prdcode)
    {
        let color,size = null;

        const products:ProductsListModel[] =  this.productList.filter((product) => {
            return product.prdcode == prdcode;
        });

        const product = products.length? products[0]: null;        

        for(let i = 0; i < product.variations.length; i++){
            if(product.variations[i].id == variationId){
                color = product.variations[i].color;
                size = product.variations[i].size;
            }           
        }    

        return {color,size};
    }
}
