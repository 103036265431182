import { Component, OnInit,OnDestroy,AfterViewInit} from '@angular/core';
import { HelperService } from '../../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CrudServiceService } from '../../../app/app-services/crud-service.service'
import * as CartActions from '../../services/cart-store/cart.action';
import * as fromCart from '../../services/cart-store/cart.reducer';
import { Store } from '@ngrx/store';
import { ProductModel } from '../../shared/models/product.model';
import { timeout } from 'q';
declare var $: any;

declare const require: any;
var qs = require('qs');
var assert = require('assert');

@Component({
selector: 'app-product-detail',
templateUrl: './product-detail.component.html',
styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit,OnDestroy,AfterViewInit {
    public productId = null;
    public submitingData: boolean = false;
    public isLoading: boolean = false;
    public similiareProducts : Array<ProductModel> = [];
    public catDetails : Array<ProductModel>= [];
    public productDescription: Array<ProductModel> = [];
    public imgList = [];
    public selectimage = null;
    public cartNumber: number = 0;
    public cartData;
    public productQuant: number = 1;
    public currentImg(img) {
        // console.log(img)
        if (img !== null) {
            this.selectimage = img
        } else {
            this.selectimage = this.selectimage
        }
    }
    constructor(private store: Store<fromCart.CartState>, private helperService: HelperService, private crud: CrudServiceService, private router: Router, private toastr: ToastrService, private activeRoute: ActivatedRoute, ) {
        let catId = window.location.href.split("/")
        this.activeRoute.params.subscribe((res: any) => {
            this.productId = res.id;
        })
        this.getProductById(this.productId)
        this.getSimilarProduct(catId[catId.length - 3]);
        this.checkUser()
    }
    getProductById(id) {
        this.isLoading = true;
        if (id !== null) {
            this.crud.getData('product/info?productId=' + id).subscribe((res: any) => {
                if (res.responseCode == 200) {
                    this.productDescription = res.products;
                    console.log('PD',res)
                    this.selectimage = this.productDescription[0].defaultImage;
                    this.productDescription[0].quantity = 1;
                    this.isLoading = false;
                }
            }, error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            })
        }
    }
    //smilar product
    getSimilarProduct(catId) {
       
        this.crud.getData('product/category/list?categoryId=' + catId).subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.similiareProducts = res.products;
                if (res.products.length > 8) {
                    this.catDetails = res.products.splice(0, 6)
                } else {
                    this.catDetails = res.products
                }
            }
        })
    }
    public checkUser() {
        if (localStorage.getItem("type") === "Regular" || localStorage.getItem("type") == undefined) {
            return false;
        } else {
            return true;
        }
    }
    //select quantity
    public selectQuant(value) {
        this.productQuant = value;       
    }
    //public catDetails:ProductModel[] = [{ title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Printed Tees", image: "assets/img/pic4.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Striped", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Solid", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Printed Tees", image: "assets/img/pic4.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Striped", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Solid", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }]
    addToCart(code,isRelated,$event) {

        if(isRelated){
            var el = $($event.target).parents('.product_thumb').next().find(".variation-option-size").children('select').val();
            var options = this.getRelatedVariationDetail(el,code);  
        }else{
            var el = $($event.target).parents('.action').next('.product_meta').find(".variation-option-size").children('select').val();      
            var options = this.getColorAndSizeByVariation(el,code);  
        }        
       
        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            fetch(this.crud.getEndPoint("cart/add"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                },
                body: qs.stringify({
                    email: localStorage.getItem("email"),
                    customerId: localStorage.getItem("customerid"),
                    productId: code,
                    variationId:el,
                    color:options.color,
                    size:options.size,
                    quantity: this.productQuant
                })
            }).then(response => response.json())
            .then(response => {
                if (response.responseCode == "200") {
                    
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                    this.router.navigate(['/cart-page'])
                }

                if(response.responseCode == 100 ){
                    this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 });
                }
            }).catch(error => console.error('Error:', error))
        }
    }
    public productDetails(catId) {
        
        this.router.navigate(['/catId/' + catId.categoryId + '/product-detalil/' + catId.prdcode]);
        this.getProductById(catId.prdcode)
    }
    public addToWishList(prodId,isRelated,$event) {

        if(isRelated){
            var el = $($event.target).parents('.product_thumb').next().find(".variation-option-size").children('select').val();
            var options = this.getRelatedVariationDetail(el,prodId);  
        }else{
            var el = $($event.target).parents('.action').next('.product_meta').find(".variation-option-size").children('select').val();      
            var options = this.getColorAndSizeByVariation(el,prodId);  
        }       

        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            if (localStorage.getItem("customerid") !== null || localStorage.getItem("customerid") !== undefined) {
                fetch(this.crud.getEndPoint("wishlist/create"),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                    },
                    body: qs.stringify({
                        customerId: localStorage.getItem("customerid"),
                        productId: prodId,
                        variationId:el,
                        color:options.color,
                        size:options.size,
                    })
                }).then(response => response.json())
                .then(response => {
                    if (response.responseCode == "200") {
                        this.helperService.wishListSubject.next(response.wishListItems)
                        this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                        this.router.navigate(['/wishlist']);
                        this.submitingData = false;
                    }
                }).catch(error => console.error('Error:', error));
                this.submitingData = false
            } else {
                this.toastr.warning("Please Login First", "Login In Required", { timeOut: 3000 })
                this.submitingData = false
            }
        }
    }   

    ngOnInit() {    
    }  

    ngOnDestroy(){
        if($('.zoomContainer').length > 0){
            $('.zoomContainer').remove();    
            $.removeData($("#gallery_01"), 'elevateZoom');
            $("#zoom1").removeData('elevateZoom');
        }  
    }   

    ngAfterViewInit() {
        
        if($('.zoomContainer').length > 0){
            $('.zoomContainer').remove();    
            $.removeData($("#gallery_01"), 'elevateZoom');
            $("#zoom1").removeData('elevateZoom');
        }  

        this.activeRoute.params.subscribe(routeParams => {

            if($('.zoomContainer').length > 0){
                $('.zoomContainer').remove();    
                $.removeData($("#gallery_01"), 'elevateZoom');
                $("#zoom1").removeData('elevateZoom');
            } 
            
            setTimeout(function(){ 
                $('.single-product-active').owlCarousel({
                    refresh:true,
                    autoplay: true,
                    loop: true,
                    nav: true,                
                    autoplayTimeout: 8000,
                    items: 4,
                    margin:15,
                    dots:false,
                    navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
                    responsiveClass:true,
                    responsive:{
                            0:{
                            items:1,
                        },
                        320:{
                            items:2,
                        },
                        400:{
                            items:3,
                        },
                        992:{
                            items:3,
                        },
                        1200:{
                            items:4,
                        },
                    }
                });    

                $("#zoom1").elevateZoom({
                    gallery:'gallery_01', 
                    responsive : true,
                    cursor: 'crosshair',
                    zoomType : 'inner'            
                });   

                $('.product_column5').on('changed.owl.carousel initialized.owl.carousel', function (event) {
                    $(event.target).find('.owl-item').removeClass('last').eq(event.item.index + event.page.size - 1).addClass('last')}).owlCarousel({
                    autoplay: false,
                    loop: true,
                    nav: true,                   
                    // autoplayTimeout: 8000,
                    items: 5,
                    margin: 20,
                    dots:false,
                    navText: ['<i class="ion-ios-arrow-left"></i>','<i class="ion-ios-arrow-right"></i>'],
                    responsiveClass:true,
                    responsive:{
                        0:{
                            items:1,
                        },
                        576:{
                            items:2,
                        },
                        768:{
                            items:3,
                        },
                        992:{
                            items:4,
                        },
                        1200:{
                            items:5,
                        },
                    }
                });

            },2000); 
        }); 
    }

    onSizeAndColorChange(variationId:string,prdcode:string,$event): void 
    {
       var options = this.getColorAndSizeByVariation(variationId,prdcode);                    
      
        $($event.target).parent().siblings('.option-label').find('select').val(variationId).change();       
    }

    getColorAndSizeByVariation(variationId,prdcode)
    {
        let color,size = null;       

        for(let i = 0; i < this.productDescription[0].variations.length; i++){
            if(this.productDescription[0].variations[i].id == variationId){
                color = this.productDescription[0].variations[i].color;
                size = this.productDescription[0].variations[i].size;
            }           
        }    

        return {color,size};
    }

    getRelatedVariationDetail(variationId,prdcode)
    {
        
        let color,size = null;

        const products:ProductModel[] =  this.catDetails.filter((product) => {
            return product.prdcode == prdcode;
        });

        console.log("SIMILIAR PRODUCT ", products);

        const product = products.length? products[0]: null;  
        for(let i = 0; i < product.variations.length; i++){
            if(product.variations[i].id == variationId){
                color = product.variations[i].color;
                size = product.variations[i].size;
            }           
        }    

        return {color,size};
    }
}
