import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CrudServiceService } from './crud-service.service';
import { Observable } from 'rxjs';

@Injectable()
export class CrudInterceptorService implements HttpInterceptor {
    constructor(private crud: CrudServiceService) {

    }

    public intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> | any {
        let headers = {
            'Content-Type': 'application/json',
        };
        if (this.crud.getAuthToken) {
            headers['Authorization'] = this.crud.getAuthToken;
        }
        //console.log('headers', headers);
        req = req.clone({
            setHeaders: headers
        });
        return handler.handle(req);
    }
}
