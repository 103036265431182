import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e-commerce';
  public loginPage: boolean = false;
  constructor() {
    let url = document.URL
    if (url) {
      url == "http://localhost:4200/#/login";
      this.loginPage = true;
    }
  }
}
