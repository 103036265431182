import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { CrudServiceService } from '../../../app-services/crud-service.service';
declare const require: any;
var qs = require('qs');
var assert = require('assert');
@Component({
  selector: 'app-saved-address',
  templateUrl: './saved-address.component.html',
  styleUrls: ['./saved-address.component.scss']
})
export class SavedAddressComponent implements OnInit {
  public isLoading: boolean = false;
  public addressList = [];
  public latestAdress = {};
  constructor(private toastr: ToastrService, private crud: CrudServiceService) {
    this.getAllAddress()
  }

  ngOnInit() {
  }
  //get address
  getAllAddress() {
    this.isLoading = true;
    this.crud.getData('customer/address/list?customerId=' + localStorage.getItem("customerid")).subscribe((res: any) => {
      // console.log("asedrftgyhujik")
      if (res.responseCode == 200) {
        this.addressList = res.addresses;
        this.latestAdress = this.addressList[this.addressList.length - 1]
        // console.log(this.addressList)
        this.isLoading = false;
      } else {
        this.toastr.error('Error', 'Something went wrong', { timeOut: 3000 })
      }
    })
  }
}
