import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../../pages/login/login.component';
import { ForgotPasswordComponent } from '../../pages/forgot-password/forgot-password.component';
//Route f or content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },   
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
];
