import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'

declare const require: any;
var qs = require('qs');
var assert = require('assert');

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    public isLoading: boolean = false;
    public wishlist = true;
    public savedAddress = false;
    public editProfile = false;    

    public addressList = [];    

    public orderList: any;
    public productList = [];
    public prodInfo = [];
    public taxValue = 0;
    p: number = 1;
    public modalReference: NgbModalRef;

    public wishList = [];
    public displayToaster: boolean = true;
    public isSaving: boolean = false;

    public latestAddress = {
        addressId:null,
        firstName:null,
        lastName:null,
        address:null,
        city:null,
        state:null,
        zip:null,
        country:null
    };

    constructor(public crud: CrudServiceService,private toaster: ToastrService,private modalService: NgbModal,public router: Router) {       
        this.orderHistoryList();
        this.taxValue = +localStorage.getItem("taxValue");
        this.getAllAddress();
        this.getWishListData();
    }

    ngOnInit() {}

    getAllAddress() {
        this.isLoading = true;
        this.crud.getData('customer/address/list?customerId=' + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.addressList = res.addresses;

                if(res.addresses.length > 0){
                    this.latestAddress = this.addressList[this.addressList.length - 1]; 
                }else{
                    this.latestAddress = {
                        addressId:"",
                        firstName:"",
                        lastName:"",
                        address:"",
                        city:"",
                        state:"",
                        zip:"",
                        country:""
                    };
                }
                               
                //console.log("ADDRESS ACCOUNT",res.addresses);
                this.isLoading = false;
            } else {
                this.toaster.error('Error', 'Something went wrong', { timeOut: 3000 })
            }
        })
    }

    //get order history
    public orderHistoryList() {
        this.isLoading = true;
        this.crud.getData("customer/order/history?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode == 200) {
                // console.log("res", JSON.stringify(res))
                this.orderList = res.salesOrders;
                this.orderList.forEach((ele) => {
                    let totalPrice = 0;
                    ele.productList.forEach(data => {
                        totalPrice += +data.price;
                    })
                    // console.log("totalPrice", totalPrice)
                    ele.taxValue = (totalPrice * this.taxValue) / 100
                })
                // console.log("sdrftgyhj", this.orderList)
                this.isLoading = false;
            }
        }, error => {
            this.toaster.error('Something went wrong', 'Error', { timeOut: 3000 });
            this.isLoading = false;
        })
    }
    
    //order Details 
    openOrderDetail(content, productList, data) {
        this.modalReference = this.modalService.open(content, { backdrop: true, keyboard: false });
        // console.log("data.productList", productList)
        this.productList = productList;
        this.prodInfo = data;
    }

    // wishlist start here
    public getWishListData() {
        this.isLoading = true;
        this.crud.getData("wishlist/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode == 200) {
                // console.log(res)
                this.wishList = res.wishListItems;
                this.isLoading = false;
            } else {
                this.toaster.error("Error", "Something went wrong", { timeOut: 3000 });
                this.isLoading = false;
            }
        })
    }

    //remove from wishlist
    removeProduct(id) {
        fetch(this.crud.getEndPoint("wishlist/remove"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                wishListId: id,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == 200) {
                this.getWishListData();
                if (this.displayToaster) {
                    this.toaster.success(response.responseMessage, response.description, { timeOut: 3000 })
                }

            } else {
                if (this.displayToaster) {
                    this.toaster.error("Error", "Something went wrong", { timeOut: 3000 })
                }
            }
        })
        .catch(error => console.error('Error:', error));
    }

    addToCart(code, listId) {
        this.isSaving = true;
        // console.log(code)
        fetch(this.crud.getEndPoint("cart/add"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                email: localStorage.getItem("email"),
                customerId: localStorage.getItem("customerid"),
                productId: code,
                quantity: 1
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                // console.log("added", response)
                this.toaster.success(response.responseMessage, response.description, { timeOut: 3000 });
                this.isSaving = false;
                this.displayToaster = false;
                this.removeProduct(listId)
                this.router.navigate(['/cart-page'])
            }
        }).catch(error => console.error('Error:', error));
        this.isSaving = false;
    }

    public RemoveAdress(addressId){

    }

    signout() {
        localStorage.clear();
        this.router.navigate(['/login'])
    }
}
